import { MouseEvent } from 'react'
import { IconProps } from "@/utils/types"

interface ChatArrowIcon extends IconProps {
  onClick: (e: MouseEvent) => void;
  allowDarkMode?: boolean;
}

export function ChatArrowIcon(props: ChatArrowIcon) {
  const { onClick, className, allowDarkMode = true, ...rest } = props

  return (
    <div onClick={onClick} {...rest} className={`cursor-pointer ${className}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role='img' aria-label='An arrow represnting an event of sending a message'>
        <path
          className={allowDarkMode ? "fill-[#CF4500] dark:fill-white cursor-pointer group-hover:fill-black dark:group-hover:fill-orange-100" : "fill-[#CF4500]"}
          d="M22.5253 11.438L4.02833 0.757991C3.80333 0.630991 3.52833 0.650991 3.32533 0.812991C3.12333 0.975991 3.04233 1.23999 3.12133 1.48799L5.81733 9.99999H6.86633L4.36633 2.10699L21.5003 12L4.28633 21.938L6.88633 12H16.0003V11H10.0003H6.57733H6.13433L3.09233 22.555C3.02933 22.799 3.11733 23.057 3.31933 23.21C3.43133 23.294 3.56433 23.337 3.69733 23.337C3.80433 23.337 3.91233 23.31 4.00933 23.253L22.5243 12.564C22.7253 12.448 22.8503 12.232 22.8503 12.001C22.8503 11.77 22.7253 11.553 22.5253 11.438Z" />
      </svg>
    </div>
  )
}

