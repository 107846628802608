import { IconProps } from "@/utils/types"

interface ArrowRightIconProps extends IconProps {
  fill?: string;
}

export function ArrowRightIcon(props: ArrowRightIconProps) {
  const fill = props.fill ?? "#54524F"

  return (
    <div {...props}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="An arrow pointing right representing an action of visiting a new page">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.22423 2.13568C8.51712 1.84279 8.992 1.84279 9.28489 2.13568L14.6182 7.46902C14.7588 7.60967 14.8379 7.80044 14.8379 7.99935C14.8379 8.19826 14.7588 8.38903 14.6182 8.52968L9.28489 13.863C8.992 14.1559 8.51712 14.1559 8.22423 13.863C7.93134 13.5701 7.93134 13.0952 8.22423 12.8023L12.2772 8.74935H3.08789C2.67368 8.74935 2.33789 8.41356 2.33789 7.99935C2.33789 7.58513 2.67368 7.24935 3.08789 7.24935H12.2772L8.22423 3.19634C7.93134 2.90345 7.93134 2.42858 8.22423 2.13568Z" fill={fill} />
      </svg>
    </div>
  )
}

