import { useEffect } from "react";
import SigninForm from "../Auth/Signin";
import SignupForm from "../Auth/Signup";
import { useUser } from "@clerk/nextjs";
import ForgotPassword from "../Auth/ResetPassword";
import User from "../Auth/User";
import Button from "../Button";
import { trackLinkClick } from "@/utils/analytics";
import { useAtom } from "jotai";
import { authState } from "@/utils/atoms/sidebar";

export default function Profile() {
  const { isSignedIn, user, isLoaded } = useUser()
  const [state, setState] = useAtom(authState)

  useEffect(() => {
    if (isSignedIn) {
      setState("signin")
    }
  }, [isSignedIn])

  if (!isLoaded) {
    return null
  }

  if (isSignedIn && user) {
    return <User user={user} />
  }

  if (state === "") {
    return (
      <div className="flex space-x-2">
        <Button
          className="flex justify-center items-center w-full max-w-[40%]"
          onClick={() => {
            trackLinkClick({ section: "profile", clickName: "login" })
            setState('signin')
          }}
        >
          Login
        </Button>
        <Button
          className="flex justify-center items-center"
          onClick={() => {
            trackLinkClick({ section: "profile", clickName: "create_account" })
            setState('signup')
          }}
        >
          Create account
        </Button>
      </div>

    )
  }
  if (state === "signin") {
    return <SigninForm />
  } else if (state === "signup") {
    return <SignupForm setState={setState} />
  } else if (state === "forget-password") {
    return <ForgotPassword setState={setState} />
  } else {
    return null
  }
}
