import { FormEvent, useState } from "react";
import { useSignIn } from "@clerk/nextjs";
import { EmailCodeFactor, PhoneCodeFactor } from '@clerk/types'

import Spinner from "@/components/Spinner";
import Button from "../Button";
import VerifyForm from "./Verify";
import { clerkErrorMessage } from "@/utils/utils";
import { trackLinkClick } from "@/utils/analytics";
import { useSetAtom } from "jotai";
import { authState } from "@/utils/atoms/sidebar";

const SigninForm = () => {
  const { isLoaded, signIn, setActive } = useSignIn();
  const setState = useSetAtom(authState)

  const [loading, setLoading] = useState(false)
  const [clerkError, setClerkError] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [verifying, setVerifying] = useState(false)
  const [verificationMode, setVerificationMode] = useState<'phone' | 'email'>('phone')
  const [verificationError, setVerificationError] = useState("");

  const resendCode = async (mode: 'phone' | 'email') => {
    if (mode === 'phone') {
      await signInWithPhone({ phone })
    } else {
      await signInWithEmail({ emailAddress: email })
    }
  }

  const signInWithPhone = async ({
    phone,
  }: {
    phone: string;
  }) => {
    if (!isLoaded) {
      return;
    }

    try {
      setClerkError("")
      const { supportedFirstFactors } = await signIn.create({
        identifier: phone,
      });

      // send the email.
      const phoneCodeFactor = supportedFirstFactors?.find(f => f.strategy === "phone_code")
      if (!phoneCodeFactor) {
        setClerkError("Phone verification not supported.")
        return
      }
      const { phoneNumberId } = phoneCodeFactor as PhoneCodeFactor
      await signIn.prepareFirstFactor({ strategy: "phone_code", phoneNumberId });

      // change the UI to our pending section.
      setPhone(phone)
      setVerifying(true);
      setVerificationMode('phone')
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      setClerkError(clerkErrorMessage(err, "phone_number"));
    }
  };

  const signInWithEmail = async ({
    emailAddress,
  }: {
    emailAddress: string;
  }) => {
    if (!isLoaded) {
      return;
    }

    try {
      setClerkError("")
      const { supportedFirstFactors } = await signIn.create({
        identifier: emailAddress,
      });

      // send the email.
      const emailCodeFactor = supportedFirstFactors?.find(f => f.strategy === "email_code")
      if (!emailCodeFactor) {
        setClerkError("Cannot find email code strategy.")
        return
      }
      const { emailAddressId } = emailCodeFactor as EmailCodeFactor
      await signIn.prepareFirstFactor({ strategy: "email_code", emailAddressId });

      // change the UI to our pending section.
      setEmail(emailAddress)
      setVerificationMode('email')
      setVerifying(true);
    } catch (err: any) {
      console.log(JSON.stringify(err, null, 2));
      setClerkError(clerkErrorMessage(err, "email address"));
    }
  };

  const handlePhoneSubmit = async (e: FormEvent) => {
    setLoading(true)
    e.preventDefault();
    const target = e.target as typeof e.target & {
      phone: { value: string };
    };
    const phone = target.phone.value;

    try {
      if (!phone) {
        setClerkError("Phone number is required.")
        return
      } else if (!/^\+?[0-9]{10,}$/.test(phone)) {
        setClerkError("Numbers only.")
        return
      }

      await signInWithPhone({ phone: phone });
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEmailSubmit = async (e: FormEvent) => {
    setLoading(true)
    e.preventDefault();
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;

    try {
      if (!email) {
        setClerkError("Email address is required.")
        return
      }

      await signInWithEmail({ emailAddress: email });
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handlePhoneVerify = async (e: FormEvent) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      setVerificationError("")
      const completeSignIn = await signIn.attemptFirstFactor({
        strategy: "phone_code",
        code,
      });
      if (completeSignIn.status !== "complete") {
        console.log(JSON.stringify(completeSignIn, null, 2));
      }

      if (completeSignIn.status === "complete") {
        await setActive({ session: completeSignIn.createdSessionId });
      }
    } catch (err: any) {
      console.log("Error:", JSON.stringify(err, null, 2));
      setVerificationError(clerkErrorMessage(err))
    }
  };

  const handleEmailVerify = async (e: FormEvent) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      setVerificationError("")
      const completeSignIn = await signIn.attemptFirstFactor({
        strategy: "email_code",
        code,
      });
      if (completeSignIn.status !== "complete") {
        console.log(JSON.stringify(completeSignIn, null, 2));
      }

      if (completeSignIn.status === "complete") {
        await setActive({ session: completeSignIn.createdSessionId });
      }
    } catch (err: any) {
      console.log("Error:", JSON.stringify(err, null, 2));
      setVerificationError(clerkErrorMessage(err))
    }
  };

  if (verifying && verificationMode === 'phone') {
    return (
      <VerifyForm
        verifyEmail={false}
        verifyPhone={true}
        handleVerify={handlePhoneVerify}
        phoneCode={code}
        setPhoneCode={setCode}
        verificationError={verificationError}
        changeVerificationMode={() => {
          setVerifying(false)
          setVerificationMode('email')
        }}
        resendCode={resendCode}
      />
    )
  }
  if (verifying && verificationMode === 'email') {
    return (
      <VerifyForm
        verifyEmail={true}
        verifyPhone={false}
        handleVerify={handleEmailVerify}
        emailCode={code}
        setEmailCode={setCode}
        verificationError={verificationError}
        resendCode={resendCode}
      />
    )
  }

  return (
    <div className="w-full">
      <div>
        <div>
          {verificationMode === 'phone' ? (
            <form onSubmit={handlePhoneSubmit} noValidate>
              <div className="flex items-center space-x-4 mb-2">
                <label htmlFor="phone" className="text-[16px] font-medium text-black dark:text-white">
                  Phone
                </label>
                <input
                  id="phone"
                  name="phone"
                  className="block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                  type="tel"
                  required
                />
              </div>
              {clerkError ? <p className="text-orange dark:text-orange-200 text-sm mt-2">{clerkError}</p> : null}
              <div className="inline-block w-[109px]">
                <Button
                  tabIndex={0}
                  className="flex justify-center items-center mt-2"
                  type="submit"
                  disabled={loading}
                  aria-label="Login button"
                  onClick={() => trackLinkClick({ section: "profile", clickName: "login" })}
                >
                  Login
                  {loading ? <Spinner className="ml-3" /> : null}
                </Button>
              </div>

              <div className="flex items-start mt-4">
                <p className="text-[16px] font-light text-black dark:text-white flex-1">
                  Don&apos;t have an account?
                </p>
                <a
                  href="#?signup"
                  onClick={() => {
                    trackLinkClick({ section: "profile", clickName: "create_account" })
                    setState('signup')
                  }}
                  className="ml-2 text-orange dark:text-orange-200 cursor-pointer"
                  aria-label="Create a new account"
                >
                  Sign up
                </a>
              </div>
            </form>
          ) : (
            <form onSubmit={handleEmailSubmit} noValidate>
              <div className="flex items-center space-x-4 mb-2">
                <label htmlFor="email" className="text-[16px] font-medium text-black dark:text-white">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  className="block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                  type="email"
                  required
                />
              </div>
              {clerkError ? <p className="text-orange dark:text-orange-200 text-sm mt-2">{clerkError}</p> : null}
              <div className="inline-block w-[109px]">
                <Button
                  tabIndex={0}
                  className="flex justify-center items-center mt-2"
                  type="submit"
                  disabled={loading}
                  aria-label="Login button"
                  onClick={() => trackLinkClick({ section: "profile", clickName: "login" })}
                >
                  Login
                  {loading ? <Spinner className="ml-3" /> : null}
                </Button>
              </div>

              <div className="flex items-start mt-4">
                <p className="text-[16px] font-light text-black dark:text-white flex-1">
                  Don&apos;t have an account?
                </p>
                <a
                  href="#?signup"
                  onClick={() => {
                    trackLinkClick({ section: "profile", clickName: "create_account" })
                    setState('signup')
                  }}
                  className="ml-2 text-orange dark:text-orange-200 cursor-pointer"
                  aria-label="Create a new account"
                >
                  Sign up
                </a>
              </div>
            </form>

          )}
        </div>
      </div>
    </div>
  );
};

export default SigninForm;

