import { IconProps } from "@/utils/types"

interface ThreeDotsIconProps extends IconProps {
  fill?: string;
}

export function ThreeDotsIcon(props: ThreeDotsIconProps) {
  const { fill = "#CF4500", className, ...rest } = props

  return (
    <div {...rest} className={`cursor-pointer group ${className}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="12" r="3" fill={fill} className="fill-[#CF4500] dark:fill-white cursor-pointer" />
        <circle cx="21" cy="12" r="3" fill={fill} className="fill-[#CF4500] dark:fill-white cursor-pointer" />
        <circle cx="12" cy="12" r="3" fill={fill} className="fill-[#CF4500] dark:fill-white cursor-pointer" />
      </svg>
    </div>
  )
}
