import { useController } from 'react-hook-form';
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

export const Dropdown = (props: any) => {
  const { options, expand = false } = props as { options: string[]; expand?: boolean }
  const {
    field: { value, onChange },
  } = useController({ defaultValue: "", rules: { required: true }, ...props });

  return (
    <div className='w-full'>
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default bg-white rounded border h-[40px] pl-3 pr-8 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className={`max-w-[130px] text-[14px] block truncate ${value ? "text-black" : "text-[#777470]"}`}>{value ? value : "Choose an option"}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className={`${open ? 'rotate-180 transform' : ''
                    } h-5 w-5 dark:text-black`}
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={`absolute mt-1 max-h-60 ${expand ? "-left-28 max-w-[260px]" : "w-full"} overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-[14px] z-20 text-black`}>
                {options.map((option, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${option === value ? "bg-gray-100" : ""} ${active ? "bg-gray-100" : ""}`
                    }
                    title={option}
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? "bg-gray-100" : ""}`}
                        >
                          {option}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-orange">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>

        )}
      </Listbox>
    </div>
  );
};
