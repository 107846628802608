import { Dispatch, FormEvent, ReactNode, SetStateAction, useState } from "react"

import Spinner from "@/components/Spinner";
import Button from "../Button";
import { trackLinkClick } from "@/utils/analytics";

interface VerifyFormProps {
  handleVerify: (e: FormEvent) => Promise<void>;
  emailCode?: string;
  setEmailCode?: (value: string) => void;
  phoneCode?: string;
  setPhoneCode?: (value: string) => void;
  verificationError: string;
  verifyEmailMessage?: ReactNode;
  verifyEmail: boolean;
  verifyPhone: boolean;
  changeVerificationMode?: Dispatch<SetStateAction<'phone' | 'email'>>;
  resendCode: (mode: 'phone' | 'email') => Promise<void>;
}

const VerifyForm = ({
  changeVerificationMode,
  handleVerify,
  emailCode,
  setEmailCode,
  phoneCode,
  setPhoneCode,
  verificationError,
  verifyEmailMessage,
  verifyEmail,
  verifyPhone,
  resendCode,
}: VerifyFormProps) => {
  const [loading, setLoading] = useState(false)
  const [resending, setResending] = useState(false)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true)
    try {
      await handleVerify(e);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleResendCode = async (mode: 'phone' | 'email') => {
    setResending(true)
    try {
      await resendCode(mode);
    } catch (error) {
      console.error(error)
    } finally {
      setResending(false)
    }
  }

  return (
    <div className="w-full">
      <div>
        <div>
          <form onSubmit={handleSubmit} noValidate>
            {(verifyEmail && setEmailCode) && (
              <>
                <div className="flex items-center space-x-4">
                  <label htmlFor="verificationCode" className="font-medium text-black dark:text-white">
                    Code
                  </label>
                  <input
                    value={emailCode}
                    className="block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    id="verificationCode"
                    name="verificationCode"
                    onChange={(e) => setEmailCode(e.target.value)}
                    required
                  />
                </div>
                <p className="text-black dark:text-white text-sm mt-1 mb-2">
                  {verifyEmailMessage ? verifyEmailMessage : null}
                  A code has been sent to your email.
                </p>
                <div className="flex items-center space-x-2 mb-2">
                  <button
                    type="button"
                    onClick={() => handleResendCode('email')}
                    className="block text-orange text-sm dark:text-orange-200 cursor-pointer"
                  >
                    Resend code?
                  </button>
                  {resending ? <Spinner className="h-4 w-4" /> : null}
                </div>
              </>
            )}
            {(verifyPhone && setPhoneCode) && (
              <>
                <div className="flex items-center space-x-4">
                  <label htmlFor="verificationCode" className="font-medium text-black dark:text-white">
                    Code
                  </label>
                  <input
                    value={phoneCode}
                    className="block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    id="phoneVerificationCode"
                    name="phoneVerificationCode"
                    onChange={(e) => setPhoneCode(e.target.value)}
                    required
                  />
                </div>

                <p className="text-black dark:text-white text-sm mt-1 mb-2">A code has been sent to your phone. Standard text messaging and data rates apply.</p>

                <div className="flex items-center space-x-2 mb-2">
                  <button
                    type="button"
                    onClick={() => handleResendCode('phone')}
                    className="block text-orange text-sm dark:text-orange-200 cursor-pointer"
                  >
                    Resend code?
                  </button>
                  {resending ? <Spinner className="h-4 w-4" /> : null}
                </div>

                {changeVerificationMode ? (
                  <>
                    <p className="inline-block text-black dark:text-white text-sm mb-2">No access to phone?</p>
                    <a
                      href="#?email-verification"
                      onClick={() => {
                        changeVerificationMode('email')
                      }}
                      className="ml-2 text-orange text-sm dark:text-orange-200 cursor-pointer"
                      aria-label="Verify by email"
                    >
                      Verify by email
                    </a>
                  </>
                ) : null}
              </>
            )}

            {verificationError ? <p className="text-orange dark:text-orange-200 text-sm mt-2">{verificationError}</p> : null}

            <div className="w-[109px] inline-block">
              <Button
                className="flex justify-center items-center mt-2"
                type="submit"
                disabled={loading}
                onClick={() => trackLinkClick({ section: "profile", clickName: "verify" })}
              >
                Verify
                {loading ? <Spinner className="ml-3" /> : null}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyForm

