import { atom } from 'jotai'
import { SignUpFormInput } from '@/utils/types'

export const defaultSignUpFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  industry: "",
}

export const signUpFormState = atom<SignUpFormInput>(defaultSignUpFormState)
