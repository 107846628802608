import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";
import { useSignUp, useAuth } from "@clerk/nextjs";
import { useForm, SubmitHandler, UseFormGetValues } from "react-hook-form"

import Spinner from "@/components/Spinner";
import VerifyForm from "./Verify";
import Bin from "./Bin";
import Button from "../Button";
import { AuthState } from "@/utils/types";
import { clerkErrorMessage } from "@/utils/utils";
import { trackLinkClick } from "@/utils/analytics";
import { createUser } from "@/utils/requests";
import { Dropdown } from "./Dropdown";
import { SignUpFormInput } from "@/utils/types";
import { useSetAtom } from "jotai";
import { defaultSignUpFormState, signUpFormState } from "@/utils/atoms/form";
import { useGetAtomValue } from "@/utils/hooks/useGetAtomValue";

interface SignupProps {
  setState: Dispatch<SetStateAction<AuthState>>;
}

const industries = [
  "Automotive",
  "Business Support & Supplies",
  "Computers & Electronics",
  "Construction & Contractors",
  "Education",
  "Entertainment",
  "Food & Dining",
  "Health & Medicine",
  "Home & Garden",
  "Legal & Financial",
  "Manufacturing, Wholesale, Distribution",
  "Merchants (Retail)",
  "Personal Care & Services",
  "Real Estate",
  "Travel & Transportation",
  "Other",
]
const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
  "Connecticut", "Delaware", "District Of Columbia", "Florida", "Georgia",
  "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
  "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
  "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota",
  "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island",
  "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
  "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

export default function Signup({ setState }: SignupProps) {
  const { isLoaded, signUp, setActive } = useSignUp();
  const { getToken } = useAuth();

  // TODO: Enable BIN Lookup API usage
  // const [allowed, setAllowed] = useState(false)
  const [userData, setUserData] = useState<SignUpFormInput | null>(null)
  const [allowed, setAllowed] = useState(true)
  const [emailCode, setEmailCode] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [verificationMode, setVerificationMode] = useState<'phone' | 'email'>('phone')
  const [verifying, setVerifying] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clerkError, setClerkError] = useState("");
  const [verificationError, setVerificationError] = useState("");

  const getFormState = useGetAtomValue(signUpFormState)
  const setFormState = useSetAtom(signUpFormState)

  const { control, register, handleSubmit, watch, setError, clearErrors, formState: { errors } } = useForm<SignUpFormInput>({
    defaultValues: getFormState() as SignUpFormInput,
    shouldUnregister: false,
  })

  // Persist form data
  useEffect(() => {
    const subscription = watch((value) => {
      setFormState(value as SignUpFormInput)

      // Custom validations
      // Validate phone
      if (value.phone && !(/^\+?\d*$/.test(value.phone))) {
        setError("phone", {
          type: "custom",
          message: "Numbers only.",
        })
      } else {
        clearErrors("phone")
      }
      // Validate zip
      if (value.zip && !(/^[0-9]+$/.test(value.zip))) {
        setError("zip", {
          type: "custom",
          message: "Numbers only.",
        })
      } else {
        clearErrors("zip")
      }
      // No emojies
      Object.keys(value).forEach((key) => {
        const formKey = key as keyof SignUpFormInput
        if (["phone", "zip"].includes(formKey)) {
          return
        }

        const emojiAndTextRegEx = /^(?=.*\p{RGI_Emoji}).+$/v;
        if (emojiAndTextRegEx.test(value[formKey] as string)) {
          setError(formKey, {
            type: "custom",
            message: "Invalid input.",
          })
        } else {
          clearErrors(formKey)
        }
      })
    });

    return () => subscription.unsubscribe();
  }, [watch])

  const resendCode = async (mode: 'phone' | 'email') => {
    if (mode === 'phone') {
      await sendPhoneVerification()
    } else {
      await sendEmailVerification()
    }
  }

  const createAccount = async (user: SignUpFormInput) => {
    if (!isLoaded) {
      return;
    }

    try {
      setClerkError("")
      await signUp.create({
        emailAddress: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phone,
      });
    } catch (err: any) {
      console.log("Error:", JSON.stringify(err, null, 2));
      setClerkError(clerkErrorMessage(err));
    }
  }

  const sendPhoneVerification = async () => {
    // send the sms.
    await signUp!.prepareVerification({ strategy: "phone_code" });
    // change the UI to verification
    setVerificationMode('phone')
    setVerifying(true);
  }

  const sendEmailVerification = async () => {
    // send the email
    await signUp!.prepareEmailAddressVerification({ strategy: "email_code" });
    // change the UI to verification
    setVerificationMode('email')
    setVerifying(true)
  };

  const handleVerify = async (e: FormEvent) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      setVerificationError("")
      if (verificationMode === 'phone') {
        const verification = await signUp.attemptPhoneNumberVerification({
          code: phoneCode,
        });
        console.log(verification)

        if (verification.status !== "complete") {
          await sendEmailVerification()
        }
      } else {
        const completeSignUp = await signUp.attemptEmailAddressVerification({
          code: emailCode,
        });

        if (completeSignUp.status === "complete") {
          await setActive({ session: completeSignUp.createdSessionId });
          const fetchedToken = await getToken({ template: "supabase" });
          await createUser({
            user: {
              user_id: completeSignUp.createdUserId,
              email: userData?.email,
              first_name: userData?.firstName,
              last_name: userData?.lastName,
              phone: userData?.phone,
              address_line_1: userData?.address1,
              address_line_2: userData?.address2,
              city: userData?.city,
              state: userData?.state,
              zip_code: userData?.zip,
              industry: userData?.industry,
            }, token: fetchedToken
          })

          // Reset form data
          setFormState(defaultSignUpFormState)
        }
      }
    } catch (err: any) {
      console.error("Error:", JSON.stringify(err, null, 2));
      setVerificationError(clerkErrorMessage(err))
    }
  };

  const onSubmit: SubmitHandler<SignUpFormInput> = async (data) => {
    setUserData(data)
    await createAccount(data)
    await sendPhoneVerification()
  }

  if (!allowed) {
    return <Bin setAllowed={setAllowed} />
  }

  if (verifying) {
    return (
      <VerifyForm
        verifyPhone={verificationMode === 'phone' ? true : false}
        verifyEmailMessage={<p className="inline text-black dark:text-white text-sm mt-1 mb-2">A 2<sup>nd</sup> email verification is needed to complete the registration process.{" "}</p>}
        verifyEmail={verificationMode === 'phone' ? false : true}
        handleVerify={handleVerify}
        emailCode={emailCode}
        setEmailCode={setEmailCode}
        phoneCode={phoneCode}
        setPhoneCode={setPhoneCode}
        verificationError={verificationError}
        resendCode={resendCode}
      />
    )
  }

  return (
    <div className="w-full">
      <div>
        <div>
          <p className="pb-4">
            Please complete the mandatory fields, marked with an asterik (*).
          </p>

          <p className="pb-4">
            By registering, you acknowledge that your personal data will be processed by Mastercard as{" "}
            <a href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html" target="_blank" rel="noopener" className="text-orange dark:text-orange-200 underline">
              Privacy notice
            </a>
            .
          </p>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="flex flex-col space-y-3">
              <div className="flex justify-between items-start">
                <label htmlFor="firstName" className="pt-1 font-medium text-black dark:text-white">
                  First name*
                </label>
                <div className="w-[150px]">
                  <input
                    id="firstName"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="text"
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName ? <p className="text-orange dark:text-orange-200 text-sm mt-1">
                    {errors.firstName.type === 'custom' ? errors.firstName.message : "First name is required."}
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="lastName" className="pt-1 font-medium text-black dark:text-white">
                  Last name*
                </label>
                <div className="w-[150px]">
                  <input
                    id="lastName"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="text"
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName ? <p className="text-orange dark:text-orange-200 text-sm mt-1">
                    {errors.lastName.type === 'custom' ? errors.lastName.message : "Last name is required."}
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="email" className="w-[80px] font-medium text-black dark:text-white">
                  Business email*
                </label>
                <div className="max-w-[150px] w-full">
                  <input
                    id="email"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="email"
                    {...register("email", { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ })}
                  />
                  {errors.email ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.email.type === 'custom'
                      ? errors.email.message
                      : errors.email.type === 'pattern'
                        ? 'Enter a valid email address.'
                        : 'Email address is required.'
                    }
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="phone" className="pt-1 font-medium text-black dark:text-white">
                  Phone*
                </label>
                <div className="w-[150px]">
                  <input
                    id="phone"
                    className="remove-arrow block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="tel"
                    {...register("phone", {
                      required: true, pattern: /^\+?[0-9]{10,}$/
                    })}
                  />
                  {errors.phone ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.phone.type === 'custom'
                      ? errors.phone.message
                      : errors.phone.type === 'pattern'
                        ? 'Enter a valid phone number.'
                        : 'Phone number is required.'
                    }
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="address1" className="pt-1 font-medium text-black dark:text-white">
                  Address 1*
                </label>
                <div className="w-[150px]">
                  <input
                    id="address1"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="text"
                    {...register("address1", { required: true })}
                  />
                  {errors.address1 ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.address1.type === 'custom' ? errors.address1.message : "Address is required."}
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="address2" className="pt-1 font-medium text-black dark:text-white">
                  Address 2
                </label>
                <div className="w-[150px]">
                  <input
                    id="address2"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="text"
                    {...register("address2")}
                  />
                  {errors.address2 ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.address2.type === 'custom' ? errors.address2.message : null}
                  </p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="city" className="pt-1 font-medium text-black dark:text-white">
                  City*
                </label>
                <div className="w-[150px]">
                  <input
                    id="city"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="text"
                    {...register("city", { required: true })}
                  />
                  {errors.city ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.city.type === 'custom' ? errors.city.message : "City is required."}
                  </p> : null}
                </div>
              </div>
              <div className={`flex justify-between ${errors.state ? "items-start" : "items-center"}`}>
                <label htmlFor="state" className={`${errors.state ? "pt-2" : ""} font-medium text-black dark:text-white`}>
                  State*
                </label>
                <div className="w-full max-w-[150px]">
                  <Dropdown name="state" control={control} options={states} />
                  {errors.state ? <p className="text-orange dark:text-orange-200 text-sm mt-2">State is required</p> : null}
                </div>
              </div>
              <div className="flex justify-between items-start">
                <label htmlFor="zipCode" className="pt-1 font-medium text-black dark:text-white">
                  Zip code*
                </label>
                <div className="w-[150px]">
                  <input
                    id="zip"
                    className="remove-arrow block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                    type="tel"
                    {...register("zip", { required: true, pattern: /^[0-9]+$/ })}
                  />
                  {errors.zip ? <p className="text-orange dark:text-orange-200 text-sm mt-2">
                    {errors.zip.type === 'custom'
                      ? errors.zip.message
                      : errors.zip.type === 'pattern'
                        ? 'Enter a valid zip code.'
                        : 'Zip code is required.'
                    }
                  </p> : null}
                </div>
              </div>
              <div className={`flex justify-between ${errors.state ? "items-start" : "items-center"}`}>
                <label htmlFor="industry" className={`${errors.state ? "pt-2" : ""} font-medium text-black dark:text-white`}>
                  Industry*
                </label>
                <div className="w-full max-w-[150px]">
                  <Dropdown name="industry" control={control} options={industries} expand={true} />
                  {errors.industry ? <p className="text-orange dark:text-orange-200 text-sm mt-2">Industry is required</p> : null}
                </div>
              </div>
            </div>
            {clerkError ? <p className="text-orange dark:text-orange-200 text-sm mt-2">{clerkError}</p> : null}
            <div className="w-[140px] inline-block">
              <Button
                tabIndex={0}
                className="flex justify-center items-center mt-4"
                type="submit"
                disabled={loading}
                aria-label="Sign up button"
                onClick={() => trackLinkClick({ section: "profile", clickName: "sign_up" })}
              >
                Create account
                {loading ? <Spinner className="ml-3" /> : null}
              </Button>
            </div>
            <div className="flex items-start mt-4">
              <p className="text-[16px] font-light text-black dark:text-white flex-1">
                Already have an account?
              </p>
              <a
                href="#?login"
                onClick={() => {
                  setState("signin")
                  trackLinkClick({ section: "profile", clickName: "login" })
                }}
                className="ml-2 text-orange dark:text-orange-200 focus:underline"
                aria-label="Login to existing account"
              >
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
