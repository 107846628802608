import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useRouter } from "next/navigation";
import { useSignIn } from "@clerk/nextjs";
import { AuthState } from "@/utils/types";
import Spinner from "@/components/Spinner";
import Button from "../Button";
import { trackLinkClick } from "@/utils/analytics";

interface ForgotPasswordProps {
  setState: Dispatch<SetStateAction<AuthState>>;
}

const ForgotPassword = ({ setState }: ForgotPasswordProps) => {
  const [verifying, setVerifying] = useState(false);

  return (
    !verifying ?
      (<EmailInput setVerifying={setVerifying} setState={setState} />) :
      (<ResetPassword setState={setState} />)
  )

};

export default ForgotPassword;

const EmailInput = ({ setVerifying, setState }: {
  setVerifying: Dispatch<SetStateAction<boolean>>;
  setState: Dispatch<SetStateAction<AuthState>>;
}) => {
  const { signIn } = useSignIn();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  // Send the password reset code to the user's email
  const sendVerificationCode = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const target = e.target as typeof e.target & {
      email: { value: string };
    };
    const email = target.email.value;
    await signIn
      ?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      .then((_) => {
        setVerifying(true)
        setError('')
      })
      .catch((err) => {
        console.error('error', err.errors[0].longMessage)
        setError(err.errors[0].longMessage)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="w-full">
      <div>
        <div>
          <form onSubmit={sendVerificationCode}>
            <div className="flex items-center space-x-4">
              <label className="text-[16px] font-medium text-black dark:text-white">
                Email
              </label>
              <input
                name="email"
                className="flex-1 block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                type="email"
                required
              />
            </div>
            <h2 className="text-red-600 text-sm my-3">
              {error ? <p>{error}</p> : null}
            </h2>
            <Button
              disabled={loading}
              className="flex justify-center items-center"
              type="submit"
            >
              Send password reset code
              {loading ? <Spinner className="ml-3" /> : null}
            </Button>
          </form>
          <p className="text-[16px] mt-4 font-light text-black dark:text-white">
            Already have an account?
            <span
              className="ml-2 text-orange dark:text-orange-200 cursor-pointer"
              onClick={() => setState("signin")}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

const ResetPassword = ({ setState }: {
  setState: Dispatch<SetStateAction<AuthState>>;
}) => {
  const router = useRouter();
  const { signIn, setActive } = useSignIn();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  // Send the password reset code to the user's email
  const resetPassword = async (e: FormEvent) => {
    e.preventDefault()
    setLoading(true)
    const target = e.target as typeof e.target & {
      password: { value: string };
      code: { value: string };
    };
    const password = target.password.value;
    const code = target.code.value;
    await signIn
      ?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })
      .then((result) => {
        if (result.status === "complete") {
          setActive({ session: result.createdSessionId })
        }
        setError('')
        router.push("/assistant");
      })
      .catch((err) => {
        console.error('error', err.errors[0].longMessage)
        setError(err.errors[0].longMessage)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="w-full">
      <div>
        <div>
          <form onSubmit={resetPassword}>
            <div className="flex items-center space-x-4">
              <label className="text-[16px] font-medium text-black dark:text-white">
                New password
              </label>
              <input
                name="password"
                className="flex-1 block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                type="password"
                required
              />
            </div>
            <div className="flex items-center space-x-4">
              <label className="text-[16px] font-medium text-black dark:text-white">
                Code
              </label>
              <input
                name="code"
                className="flex-1 block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
                type="text"
                required
              />
            </div>
            <h2 className="text-red-600 text-sm my-3">
              {error ? <p>{error}</p> : null}
            </h2>
            <Button
              className="flex justify-center items-center"
              type="submit"
              disabled={loading}
              onClick={() => trackLinkClick({ section: "profile", clickName: "reset_password" })}
            >
              Reset password
              {loading ? <Spinner className="ml-3" /> : null}
            </Button>
          </form>
          <p className="text-[16px] mt-4 font-light text-black dark:text-white">
            Already have an account?
            <span
              className="ml-2 text-orange dark:text-orange-200 cursor-pointer"
              onClick={() => {
                setState("signin")
                trackLinkClick({ section: "profile", clickName: "login" })
              }}
            >
              Login
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}


