import { Dispatch, SetStateAction } from 'react'
import { SidebarViews } from '@/utils/types';
import Button from "../Button";
import ThreadsList from './ThreadsList';
import { trackLinkClick } from '@/utils/analytics';

interface SessionHistoryProps {
  setView: Dispatch<SetStateAction<SidebarViews>>;
  threads: any[];
  setThreads: Dispatch<SetStateAction<any[]>>;
  handleThreadClick: (thread: any) => void;
  deleteThread: (threadId: string) => Promise<void>;
}

export default function SessionHistory({ handleThreadClick, setView, threads, setThreads, deleteThread }: SessionHistoryProps) {
  return (
    <div className="flex-1 overflow-auto">
      <h3 className='text-lg text-base dark:text-white pb-4'>
        Session history
      </h3>
      <ThreadsList threads={threads} setThreads={setThreads} handleThreadClick={handleThreadClick} deleteThread={deleteThread} />
      <div className='w-[199px]'>
        <Button
          onClick={() => {
            setView(SidebarViews.HOME)
            trackLinkClick({ section: "session_history", clickName: "return_to_menu" })
          }}
          className='px-5 flex justify-center items-center'
        >
          Return to menu
        </Button>
      </div>
    </div>
  )
}
