import Image from "next/image";
import { useRouter } from "next/navigation";

import { LogoSize } from "@/utils/types";

interface IProps {
  showText?: boolean;
  size?: LogoSize;
  mobileView?: boolean;
}

const imageSize = {
  [`${LogoSize.SMALL}`]: 'w-[42px] sm:h-[35px]',
  [`${LogoSize.MEDIUM}`]: 'w-[60px]',
  [`${LogoSize.LARGE}`]: 'w-[64px]',
}

const fontSize = {
  [`${LogoSize.SMALL}`]: 'text-base sm:text-lg',
  [`${LogoSize.MEDIUM}`]: 'text-xl',
  [`${LogoSize.LARGE}`]: 'text-2xl',
}

export default function Logo({
  showText = false,
  size = LogoSize.SMALL,
  mobileView = false,
}: IProps) {
  const router = useRouter()

  function visitHomepage() {
    router.push("/")
  }

  return (
    <div>
      <div
        className="flex items-center space-x-2 sm:space-x-4 cursor-pointer"
        onClick={visitHomepage}
      >
        <div className={`${false ? imageSize[size] : ''}`}>
          <Image
            aria-hidden="true"
            src="/Mastercard logo.png"
            alt="Mastercard logo"
            width={55}
            height={34}
          />
        </div>
        {showText && (
          <div className="flex items-center flex-wrap">
            <p className={`mr-1.5 lg:mr-0 text-black font-medium ${mobileView ? '' : 'dark:text-white'} ${fontSize[size]}`}>
              Small Business AI
            </p>
            <p className={`block lg:hidden text-black text-sm ${mobileView ? '' : 'dark:text-white'}`}>
              BETA
            </p>
          </div>
        )}
      </div>
      <p className={`hidden lg:block ml-[71px] text-black text-sm ${mobileView ? '' : 'dark:text-white'}`}>
        BETA
      </p>
    </div>
  )
}
