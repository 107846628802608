export enum LogoSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export enum SignifierState {
  CLARIFYING = "clarifying",
  THINKING = "thinking",
  IDLE = "idle",
}

export enum SidebarViews {
  HOME = "home",
  SESSIONS = "sessions",
}

export interface IconProps {
  className?: string;
  "aria-hidden"?: boolean;
}

export interface GAEvent {
  action: string;
  category: string;
  label: string;
  value: string;
}

export type AuthState = "signin" | "signup" | "forget-password" | ""

export interface SignUpFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  industry: string;
}

export interface FeedbackModalContext {
  threadId: string | null;
  assistantResponse: string;
  assistantResponseId: string;
}

export interface Consent {
  accepted: boolean;
  timestamp: string;
}

export interface ConsentDataAccessRequest {
  consentTimestamp: string;    // ISO standard with GMT offset
  optOutTimestamp?: string;    // ISO standard with GMT offset
  status: 'Opt in' | 'Opt out' | 'Pending';
  channel: 'Web';
  country: string;                                          // Clarification
  locale: string;                                          // Clarification
  consentType: 'consentlanguagetext' | 'legalcontent';      // Clarification
  useCategory: string;                                      // Clarification
  consentText: string;                                      // Clarification
}
