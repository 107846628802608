import { Dispatch, SetStateAction } from "react";
import Spinner from "../Spinner";
import Button from "../Button";
import { SidebarViews } from "@/utils/types";
import ThreadsList from "./ThreadsList";
import { trackLinkClick } from "@/utils/analytics";

interface RecentSessionsProps {
  handleThreadClick: (thread: any) => void;
  threads: any[];
  setThreads: Dispatch<SetStateAction<any[]>>;
  setView: Dispatch<SetStateAction<SidebarViews>>;
  loading: boolean;
  deleteThread: (threadId: string) => Promise<void>;
}

export default function RecentSessions({ loading, handleThreadClick, deleteThread, threads, setThreads, setView }: RecentSessionsProps) {
  if (loading) {
    return <Spinner />
  }

  return (
    <div>
      <ThreadsList threads={threads} setThreads={setThreads} handleThreadClick={handleThreadClick} deleteThread={deleteThread} />
      <div className="inline-block">
        <Button
          onClick={() => {
            setView(SidebarViews.SESSIONS)
            trackLinkClick({ section: "recent_sessions", clickName: "session_history" })
          }}
          className='w-[199px] px-5 flex justify-center items-center'
        >
          Session history
        </Button>
      </div>
    </div>
  )
}
