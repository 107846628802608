import { useClerk } from "@clerk/nextjs";

import Button from "../Button";
import { UserResource } from "@clerk/types";
import { trackLinkClick } from "@/utils/analytics";

interface UserProps {
  user: UserResource;
}

const User = ({ user }: UserProps) => {
  const { signOut } = useClerk()

  return (
    <div className="w-full">
      <div>
        <div>
          <div className="flex items-center space-x-4">
            <label className="text-[16px] font-medium text-black dark:text-white">
              Name
            </label>
            <p className="text-[16px] text-black dark:text-white py-2 truncate">
              {user.firstName}
            </p>
            {/*<input
              name="first-name"
              className="block w-full py-0.5 px-2 mt-1 mb-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder font-light rounded-full"
              type="text"
              required
            />*/}
          </div>
          <div className="flex items-center space-x-4">
            <label className="text-[16px] font-medium text-black dark:text-white">
              Email
            </label>
            <p className="text-[16px] text-black dark:text-white truncate py-2">
              {user.primaryEmailAddress?.emailAddress}
            </p>
          </div>
          <div className="w-[109px]">
            <Button
              onClick={() => {
                trackLinkClick({ section: "profile", clickName: "log_out" })
                signOut()
              }}
              className="my-4 flex justify-center items-center"
            >
              Log out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;


