import { IconProps } from "@/utils/types"

interface ArrowTopRightIconProps extends IconProps {
  fill?: string;
}

export function ArrowTopRightIcon(props: ArrowTopRightIconProps) {
  const fill = props.fill ?? "#54524F"

  return (
    <div {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="An arrow pointing top-right representing an action of visiting a new page">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.91182 4.86086L4.18013 4.86093C3.76592 4.86094 3.43013 4.52515 3.43012 4.11094C3.43012 3.69673 3.7659 3.36094 4.18011 3.36093L11.7225 3.36084C11.9214 3.36084 12.1121 3.43985 12.2528 3.5805C12.3934 3.72115 12.4725 3.91192 12.4725 4.11083L12.4726 11.6534C12.4726 12.0676 12.1368 12.4034 11.7226 12.4034C11.3083 12.4034 10.9726 12.0676 10.9726 11.6534L10.9725 5.92148L4.47466 12.4193C4.18177 12.7122 3.70689 12.7122 3.414 12.4193C3.12111 12.1265 3.12111 11.6516 3.414 11.3587L9.91182 4.86086Z" fill={fill} />
      </svg>
    </div>
  )
}
