import Link from "next/link"
import { ArrowTopRightIcon } from "../Icons/ArrowTopRight"
import { ArrowRightIcon } from "../Icons/ArrowRight"
import { trackLinkClick } from "@/utils/analytics";

interface LegalProps {
  isDarkMode: boolean;
}

export default function Legal({ isDarkMode }: LegalProps) {
  return (
    <div className="flex justify-between items-center">
      <a
        href="https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html"
        className="text-sm flex items-center text-[#54524F] dark:text-[#B3B0A9] font-light"
        target="_blank"
        rel="noopener"
        onClick={() => trackLinkClick({ section: "", clickName: "privacy_notice" })}
      >
        Privacy Notice
        <ArrowTopRightIcon className="pl-2" fill={isDarkMode ? "#B3B0A9" : "#54524F"} />
      </a>
      <Link
        prefetch={true}
        href="/terms-of-use"
        className="text-sm flex items-center text-[#54524F] dark:text-[#B3B0A9] font-light"
        onClick={() => trackLinkClick({ section: "", clickName: "terms_of_use" })}
      >
        Terms of Use
        <ArrowRightIcon className="pl-2" fill={isDarkMode ? "#B3B0A9" : "#54524F"} />
      </Link>
    </div>
  )
}
