import { MouseEvent } from 'react'
import { IconProps } from "@/utils/types"

interface CheckMarkIcon extends IconProps {
  onClick: (e: MouseEvent) => void;
}

export function CheckMarkIcon(props: CheckMarkIcon) {
  const { onClick, ...rest } = props

  return (
    <div onClick={onClick} {...rest} className="cursor-pointer group">
      <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="stroke-[#CF4500] dark:stroke-white cursor-pointer group-hover:stroke-black dark:group-hover:stroke-orange-100" d="M1.5 14.4L11.1 24L25.5 2.40002" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  )
}
