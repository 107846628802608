import { ButtonHTMLAttributes, ReactNode } from 'react'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
}

export default function Button({ children, className, ...props }: ButtonProps) {
  return (
    <button
      {...props}
      className={`h-[50px] text-base ${className} py-2.5 w-full rounded-full bg-orange text-white dark:bg-white dark:text-black focus:outline`}
    >
      {children}
    </button>
  )
}
