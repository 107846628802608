import { Dispatch, FormEvent, useState, SetStateAction } from "react"
import Link from "next/link";

import { LogoSize } from "@/utils/types";
import Logo from "@/components/Logo";
import Spinner from "@/components/Spinner";

interface BinProps {
  setAllowed: Dispatch<SetStateAction<boolean>>;
}

const Bin = ({ setAllowed }: BinProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const handleVerify = async (e: FormEvent) => {
    e.preventDefault()

    if (cardNumber.length !== 8) {
      setError("Please enter the first 8 digits.")
      return
    }
    setLoading(true)
    setError("")
    try {
      const response = await fetch("/api/bin-lookup", {
        method: "POST",
        body: JSON.stringify({ cardNumber })
      })
      const data = await response.json()
      if (data.error) {
        setError(data.error)
      } else if (data.allowAccess) {
        setAllowed(true)
      } else {
        setAllowed(false)
        setError("Only Mastercard credit cards are accepted.")
      }
    } catch (error) {
      setError("An error occured. Please try again later")
      throw error
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-center pt-12 grid justify-items-center md:pt-20">
      <div className="h-auto shadow-xl bg-white dark:bg-gray rounded-xl md:rounded-3xl w-80 md:w-96">
        <div className="p-6 md:p-8">
          <div className="flex h-16 shrink-0 items-center">
            <Logo showText={true} size={LogoSize.SMALL} />
          </div>
          <h1 className="text-black dark:text-white mb-6 text-xl font-medium">
            Sign Up
          </h1>
          <form onSubmit={handleVerify}>
            <label className="text-sm font-medium text-black dark:text-white">
              First 8 digits of your credit card
            </label>
            <input
              value={cardNumber}
              className="block w-full p-2 mt-1 mb-2 text-black bg-white placeholder-gray-placeholder text-sm font-light bg-transparent border rounded-md border-border-default"
              id="card-number"
              name="card-number"
              onChange={(e) => setCardNumber(e.target.value)}
              required
            />

            {error ?
              <p className="my-3 text-sm text-orange dark:text-orange-200">{error}</p> :
              null
            }
            <button
              className="flex justify-center items-center w-full py-2.5 mb-6 text-sm font-light text-white bg-action-primary-default rounded-md"
              type="submit"
              disabled={loading}
            >
              Next
              {loading ? <Spinner className="ml-3" /> : null}
            </button>
          </form>
          <p className="text-sm font-light text-center text-black dark:text-white">
            Already have an account?
            <Link className="ml-2 text-action-primary-default" href="/sign-in">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Bin
