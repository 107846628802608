import { createClient } from '@supabase/supabase-js';

export const supabaseClient = async (supabaseToken) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    {
      global: {
        headers: {
          ...(supabaseToken ? { Authorization: `Bearer ${supabaseToken}` } : {})
        }
      },
    }
  );

  return supabase;
};

/**
 * ONLY FOR SERVICE ROLE DO NOT USE FOR USER
 * @returns 
 */
export const supabaseClientServiceRole = async () => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PRIVATE_SUPABASE_KEY
  );

  return supabase;
}
