import { MouseEvent } from 'react'
import { IconProps } from "@/utils/types"

interface EditIcon extends IconProps {
  onClick: (e: MouseEvent) => void;
}

export function EditIcon(props: EditIcon) {
  const { onClick, className, ...rest } = props

  return (
    <div onClick={onClick} {...rest} className={`cursor-pointer ${className}`}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill-[#CF4500] dark:fill-white cursor-pointer group-hover:fill-black dark:group-hover:fill-orange-100" d="M21.596 3.91906L18.06 0.383057L2.11395 16.3301L2.01895 16.4251L0.751953 21.1901L5.26595 20.2481L21.596 3.91906ZM20.182 3.91906L18.414 5.68706L16.293 3.56606L18.061 1.79706L20.182 3.91906ZM2.13495 19.8801L2.91595 16.9421L15.585 4.27206L17.707 6.39406L4.77095 19.3301L2.13495 19.8801Z" fill="#CF4500" />
      </svg>
    </div>
  )
}

