import { ConsentDataAccessRequest } from "./types"

const clerkErrorCodeMessages: {
  [k: string]: string
} = {
  'form_param_format_invalid': "Please enter a valid email address",
}

export function clerkErrorMessage(error: any, context?: string): string {
  const code: string = error.errors[0].code
  if (code === 'form_param_format_invalid') {
    const paramName = error.errors[0].meta.paramName
    if (paramName === 'phone_number') {
      return "Please enter a valid phone number."
    } else if (paramName === 'email_address') {
      return "Please enter a valid email address."
    } else {
      return `Please enter a valid ${context ? context.replace("_", " ") : "identifier"}.`
    }
  } else if (code === 'authorization_invalid') {
    if (error.errors[0].longMessage === 'You are not authorized to perform this request') {
      return "Your account has now been removed due to violating our guidelines. If you believe this action has happened in error, please contact small.business.ai@mastercard.com"
    }
  } else if (code === 'form_identifier_not_found') {
    return "If this is a valid account, you will receive a code."
  }

  if (clerkErrorCodeMessages[code]) {
    return clerkErrorCodeMessages[code]
  }
  return error.errors[0].longMessage
}

export async function generateConsentRequest({
  consentText,
}: {
  consentText: string;
}): Promise<ConsentDataAccessRequest> {
  // Consent timestampt
  const date = new Date();
  const offset = -date.getTimezoneOffset();
  const sign = offset >= 0 ? "+" : "-";
  const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
  const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');
  const consentTimestamp = date.toISOString().slice(0, 16).replace('T', ' ') + `${sign}${offsetHours}${offsetMinutes}`;

  // Consent locale and country
  const response = await fetch('/api/locale')
  const { locale, country } = await response.json()

  return {
    consentTimestamp,
    status: 'Opt in',
    channel: 'Web',
    consentText,
    locale,
    country,
    consentType: 'legalcontent',
    useCategory: 'UC-01',
  }
}

export async function geolocate(ip: string) {
  try {
    // TODO: Upgrade to pro service (HTTPS and no rate limits)
    // Ref: https://members.ip-api.com
    if (ip === "::1") {
      return { countryCode: "", isUS: false }
    }
    const resp = await fetch(`http://ip-api.com/json/${ip}?fields=countryCode`)
    if (!resp.ok) {
      const data = await resp.json()
      console.error(data)
      throw new Error("Geolocation failed")
    }
    const { countryCode } = await resp.json()
    // Allow access if countryCode == "US"
    return { countryCode, isUS: countryCode === "US" }
  } catch (err) {
    console.error(err)
    return { countryCode: "", isUS: false }
  }
}


