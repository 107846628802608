import { Dispatch, SetStateAction, useState, MouseEvent, cache } from "react";
import { Menu, Transition } from '@headlessui/react'
import { useAuth } from "@clerk/nextjs";
import { XMarkIcon } from "@heroicons/react/24/outline"

import { updateThreadDescription } from "@/utils/requests";
import { CheckMarkIcon } from "../Icons/CheckMark";
import { ThreeDotsIcon } from "../Icons/ThreeDotsIcon";
import { ChatArrowIcon } from "../Icons/ChatArrow";
import { EditIcon } from "../Icons/Edit";
import { DeleteIcon } from "../Icons/DeleteIcon";
import Loading from "../Loading";
import Spinner from "../Spinner";
import { trackLinkClick } from "@/utils/analytics";

interface ThreadsListProps {
  threads: any[];
  setThreads: Dispatch<SetStateAction<any[]>>;
  handleThreadClick: (thread: any) => void;
  deleteThread: (threadId: string) => Promise<void>;
}

export default function ThreadsList({ threads, setThreads, handleThreadClick, deleteThread }: ThreadsListProps) {
  const { getToken } = useAuth()

  const [editIdx, setEditIdx] = useState(-1)
  const [editValue, setEditValue] = useState('')
  const [deleting, setDeleting] = useState(false)

  const edit = (idx: number, desc: string) => {
    setEditIdx(idx)
    setEditValue(desc)
  }

  async function handleDelete(e: MouseEvent, close: () => void, threadId: string) {
    e.preventDefault()
    try {
      setDeleting(true)
      await deleteThread(threadId)
      // Update value in UI
      setThreads(prev => {
        return prev.filter(t => t.thread_id != threadId)
      })
    } catch (error) {
      console.error(error)
    } finally {
      setDeleting(false)
    }
    close()
  }

  async function update() {
    // Update value in supabase
    const token = await getToken({ template: "supabase" });
    await updateThreadDescription({
      token,
      description: editValue,
      threadId: threads[editIdx].id,
    })

    // Update value in UI
    setThreads(prev => {
      const threads = [...prev]
      threads[editIdx].description = editValue
      return threads
    })
    // Reset values
    setEditIdx(-1)
    setEditValue('')
  }

  return (
    <nav className="flex flex-1 flex-col pb-5">
      <ul role="list" className="text-black dark:text-white fd-mx-2 space-y-4">
        {threads
          .map((thread, idx) => (
            <li key={thread.thread_id}>
              {editIdx === idx ?
                <div className="flex justify-between items-center space-x-4">
                  <input
                    name="description"
                    className="block w-full py-0.5 px-2 text-black bg-editable-textbox-light dark:bg-editable-textbox-dark placeholder-gray-placeholder rounded-full"
                    type="text"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    required
                  />
                  <CheckMarkIcon
                    onClick={update}
                  />
                </div>
                :
                <div className="flex justify-between items-center space-x-4 px-2">
                  <div
                    id={thread.thread_id}
                    className="cursor-pointer"
                  >
                    <p
                      className="font-medium [@media(max-width:400px)]:w-[170px] w-[195px] truncate"
                    >
                      {thread.description}
                    </p>
                  </div>
                  <div className="space-x-3 flex justify-center items-center">
                    <Menu as="div" className="relative inline-block text-left">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="flex items-center">
                              <span className="sr-only">Open options</span>
                              {open ?
                                <XMarkIcon className="h-6 w-6 dark:text-white text-orange hover:text-black hover:dark:text-orange-100" aria-hidden={true} />
                                :
                                <ThreeDotsIcon aria-hidden={true} />
                              }
                            </Menu.Button>
                          </div>

                          <Transition
                            enter="transition duration-100 ease-out"
                            leave="transition duration-75 ease-out"
                          >
                            <Menu.Items
                              static
                              className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in dark:bg-gray-600"
                            >
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`flex items-center px-3 py-2 ${active ? 'text-black dark:text-orange-100' : 'text-orange dark:text-white'} outline-none group`}
                                      onClick={() => {
                                        edit(idx, thread.description)
                                        trackLinkClick({ section: "session_history", clickName: "rename" })
                                      }}
                                    >
                                      <EditIcon
                                        className="pr-3"
                                        onClick={() => edit(idx, thread.description)}
                                      />
                                      Rename
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`flex items-center px-3 py-2 ${active ? 'text-black dark:text-orange-100' : 'text-orange dark:text-white'} outline-none group`}
                                      onClick={() => {
                                        handleThreadClick(thread)
                                        trackLinkClick({ section: "session_history", clickName: "relaunch" })
                                      }}
                                    >
                                      <ChatArrowIcon
                                        className="pr-3"
                                        onClick={() => handleThreadClick(thread)}
                                      />
                                      Relaunch
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active, close }) => (
                                    <button
                                      disabled={deleting}
                                      onClick={(e) => {
                                        handleDelete(e, close, thread.thread_id)
                                        trackLinkClick({ section: "session_history", clickName: "delete" })
                                      }}
                                      className={`flex items-center px-3 py-2 ${active ? 'text-black dark:text-orange-100' : 'text-orange dark:text-white'} outline-none group`}
                                    >
                                      {deleting
                                        ? <Spinner className="mr-3" />
                                        : <DeleteIcon className="pr-3" />
                                      }
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              }
            </li>
          ))}
      </ul>
    </nav>
  )
}
