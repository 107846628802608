import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Button from './Button'
import { useEffect, useState, useRef } from 'react'
import Toggle from './Toggle'
import Profile from './Sidebar/Profile'
import { useDarkMode } from '@/utils/hooks/useDarkMode'
import { useUser } from "@clerk/nextjs";
import { ArrowRightIcon } from './Icons/ArrowRight'
import RecentSessions from './Sidebar/RecentSessions'
import { SidebarViews } from '@/utils/types'
import SessionHistory from './Sidebar/SessionHistory'
import Link from 'next/link'
import Legal from './Sidebar/Legal'
import { trackLinkClick } from '@/utils/analytics'
import { useAtom } from 'jotai'
import { isProfileDropdownOpen } from '@/utils/atoms/sidebar'

interface SidebarAccordionProps {
  handleThreadClick?: (thread: any) => void;
  showLegal?: boolean;
  refreshThreads?: boolean;
  deleteThread?: (threadId: string) => Promise<void>;
}

export default function SidebarAccordion({
  handleThreadClick,
  deleteThread,
  showLegal = true,
  refreshThreads = false,
}: SidebarAccordionProps) {
  const { isDarkMode } = useDarkMode()
  const { isSignedIn, isLoaded, user } = useUser()

  const profileDropdownButton = useRef<HTMLButtonElement | null>(null)

  const [isDropdownOpen, setIsDropdownOpen] = useAtom(isProfileDropdownOpen)
  const [threads, setThreads] = useState<any[]>([])
  const [loadingHistory, setLoadingHistory] = useState(true)
  const [view, setView] = useState<SidebarViews>(SidebarViews.HOME)

  const toggleDarkMode = (isDarkMode: boolean) => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
      trackLinkClick({ section: "settings", clickName: "dark_mode" })
    } else {
      document.documentElement.classList.remove("dark");
      trackLinkClick({ section: "settings", clickName: "light_mode" })
    }
  };

  const toggleProfileDropdown = (state: 'open' | 'close') => {
    if (!profileDropdownButton.current) return;

    if (profileDropdownButton.current.dataset.headlessuiState === 'open' && state === 'close') {
      profileDropdownButton.current.click()
    } else if (profileDropdownButton.current.dataset.headlessuiState !== 'open' && state === 'open') {
      profileDropdownButton.current.click()
    }
  }

  // Ensure isProfileDropdownOpen state is consistent with the headlessui state
  useEffect(() => {
    if (!profileDropdownButton.current) return;

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((m) => {
        if (m.type === 'attributes') {
          if (profileDropdownButton.current?.dataset.headlessuiState === 'open') {
            setIsDropdownOpen(true)
          } else {
            setIsDropdownOpen(false)
          }
        }
      })
    })
    observer.observe(profileDropdownButton.current, {
      attributes: true,
      attributeFilter: ["data-headlessui-state"]
    })

    return () => {
      observer.disconnect()
    }
  }, [profileDropdownButton.current])

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      toggleProfileDropdown('open')
    }
  }, [isLoaded, isSignedIn])

  useEffect(() => {
    toggleProfileDropdown(isDropdownOpen ? 'open' : 'close')
  }, [isDropdownOpen])

  useEffect(() => {
    const getThreads = async () => {
      if (!user) return;

      if (!threads.length) {
        // Do not show loading spinner when updating the threads list
        // Spinner should only be shown when the data is loaded for the first time.
        setLoadingHistory(true)
      }
      const response = await fetch("/api/history", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();
      setThreads(data.threads);
      setLoadingHistory(false)
    };

    getThreads()
  }, [user, refreshThreads])


  if (view === SidebarViews.HOME) {
    return (
      <div className="w-full">
        <div className="mx-auto w-full max-w-md py-6">
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={profileDropdownButton} className="flex w-full items-center justify-between text-black dark:text-white">
                  <h3 className='text-lg text-black dark:text-white'>
                    Profile
                  </h3>
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''
                      } h-5 w-5`}
                  />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="mt-3 text-[16px] text-black dark:text-white">
                    <Profile />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>

          {(isSignedIn && handleThreadClick && deleteThread) ? (
            <Disclosure as="div" className="mt-6">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full items-center justify-between text-black dark:text-white">
                    <h3 className='text-lg text-black dark:text-white'>
                      Recent sessions
                    </h3>
                    <ChevronDownIcon
                      className={`${open ? 'rotate-180 transform' : ''
                        } h-5 w-5`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="mt-3 text-[16px] text-black dark:text-white">
                      <RecentSessions
                        loading={loadingHistory}
                        handleThreadClick={handleThreadClick}
                        threads={threads.slice(0, 3)}
                        setThreads={setThreads}
                        setView={setView}
                        deleteThread={deleteThread}
                      />
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>

          ) : null}

          <Disclosure as="div" className="mt-6">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between text-black dark:text-white">
                  <h3 className='text-lg text-black dark:text-white'>
                    Help
                  </h3>
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''
                      } h-5 w-5`}
                  />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="mt-3">
                    <div className='inline-block'>
                      <Link
                        href='/faq'
                        prefetch={true}
                        onClick={() => trackLinkClick({ section: "help", clickName: "faqs" })}
                      >
                        <Button tabIndex={-1} className='w-[109px] px-5 flex justify-center items-center'>
                          FAQs
                          <ArrowRightIcon className="pl-2" fill={isDarkMode ? '#000' : '#fff'} />
                        </Button>
                      </Link>
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-6">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between text-black dark:text-white">
                  <h3 className='text-lg text-black dark:text-white'>
                    Settings
                  </h3>
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''
                      } h-5 w-5`}
                  />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="mt-3">
                    <div className='flex space-x-2'>
                      <Toggle label1="Light mode" label2="Dark mode" callbackFn={toggleDarkMode} />
                    </div>
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
        {showLegal ? <Legal isDarkMode={isDarkMode} /> : null}
      </div>
    )
  } else if (view === SidebarViews.SESSIONS) {
    return (
      <div className='pt-4 sm:mt-0 flex h-[calc(100dvh-116px)]'>
        <SessionHistory
          threads={threads}
          setThreads={setThreads}
          setView={setView}
          handleThreadClick={handleThreadClick!}
          deleteThread={deleteThread!}
        />
      </div>
    )
  }
}

