// utils/requests.js
import { supabaseClient, supabaseClientServiceRole } from "./supabaseClient";

export const getAcceptTermsDetails = async ({ userId, token }) => {
  const supabase = await supabaseClient(token)
  const { data, error } = await supabase
    .from("users")
    .select('consent')
    .eq("user_id", userId)

  if (error) {
    console.error("Error retrieving consent acceptance:", error.message);
    return {}
  }

  return data[0].consent
}

export const submitAcceptTermsDetails = async ({ userId, sessionId, token }) => {
  const supabase = await supabaseClient(token)
  const currentTime = new Date()
  const { error } = await supabase
    .from("users")
    .update({
      consent: {
        accepted: true,
        timestamp: currentTime.toISOString(),
        sessionId,
      }
    })
    .eq("user_id", userId)

  if (error) {
    console.error("Error updating consent acceptance:", error.message);
  }
}

export const getThread = async ({ userId, token, threadId }) => {
  const supabase = await supabaseClient(token)
  const { data, error } = await supabase
    .from("threads")
    .select("id")
    .eq("thread_id", threadId)
    .eq("user_id", userId)

  if (error) {
    console.error("Error fetching thread:", error.message);
    return [];
  }

  return data
}

export const deleteThread = async ({ userId, token, threadId }) => {
  const supabase = await supabaseClient(token)
  const { error } = await supabase
    .from("threads")
    .delete()
    .eq("thread_id", threadId)
    .eq("user_id", userId)

  if (error) {
    console.error("Error deleting thread:", error.message);
  }
}

export const submitMessageFeedback = async ({ feedbackContext }) => {
  if (!feedbackContext.feedbackMessage) {
    throw new Error("Please select your feedback.")
  }

  const supabase = await supabaseClient();
  let threadId = feedbackContext.threadId
  if (threadId) {
    // Get Supabase thread ID
    const { data, error: functionCallError } = await supabase.rpc('get_supabase_thread_id', {
      openai_thread_id: feedbackContext.threadId
    })
    if (functionCallError) {
      throw error
    }
    threadId = data
  }

  const { error } = await supabase
    .from("message_feedback")
    .insert({
      thread_id: threadId,
      assistant_response: feedbackContext.assistantResponse,
      feedback_idx: feedbackContext.feedbackIdx,
      feedback_message: feedbackContext.feedbackMessage,
      assistant_response_id: feedbackContext.assistantResponseId,
    })

  if (error) {
    throw error
  }
};


export const updateThreadDescription = async ({ token, description, threadId }) => {
  const supabase = await supabaseClient(token);
  const { error } = await supabase
    .from("threads")
    .update({
      description
    })
    .eq("id", threadId)

  if (error) {
    console.error("Error updating thread description:", error.message);
  }
};

// Function to fetch threads from Supabase
export const getThreads = async ({ userId, token, recent }) => {
  const supabase = await supabaseClient(token);
  let query = supabase
    .from("threads")
    .select("*")
    .eq("user_id", userId)
    .order('created_at', { ascending: false })

  if (recent) {
    query = query.limit(3)
  }

  const { data: threads, error } = await query

  if (error) {
    console.error("Error fetching threads:", error.message);
    return [];
  }

  return threads;
};
// @0xDebabrata i am collecting the result from the flag() function and if its red it is inserting a violation to a counter and then if that threshold is crossed the user sees a 403. thoughts?
export const incrementRedFlags = async ({ userId, threadId, token }) => {
  if (!userId) {
    return
  }
  const supabase = await supabaseClient(token);
  // Fetch the current violations data
  const { data: userData, error: fetchError } = await supabase
    .from("users")
    .select("violations")
    .eq("user_id", userId)
    .single();

  if (fetchError) {
    console.error("Error fetching user data:", fetchError.message);
    return;
  }

  // Update the violations object
  const updatedViolations = {
    red_flags: userData.violations.red_flags + 1,
    thread_ids: [...userData.violations.thread_ids, threadId],
    last_updated: new Date().toISOString(),
  };

  // Update the user's violations in the database
  const { error: updateError } = await supabase
    .from("users")
    .update({ violations: updatedViolations })
    .eq("user_id", userId);

  if (updateError) {
    console.error("Error incrementing red flags:", updateError.message);
  }
}

export const postThread = async ({ userId, threadId, assistantId, token, description, stage }) => {
  // Log temporary threads
  if (!userId) {
    const supabase = await supabaseClientServiceRole()
    await supabase
      .from("temp_threads")
      .insert({
        thread_id: threadId,
        assistant_id: assistantId,
      })
    return;
  }

  const supabase = await supabaseClient(token);
  const { data, error } = await supabase
    .from("threads")
    .insert({
      user_id: userId,
      thread_id: threadId,
      assistant_id: assistantId,
      description,
      selected_stage: stage,
    })
    .select("id");

  console.log(data)
  if (error) {
    console.error("Error posting thread:", error.message);
    return null;
  }

  return data;
};

export const createUser = async ({ user, token }) => {
  const supabase = await supabaseClient(token);
  const { data, error } = await supabase
    .from("users")
    .insert(user)
    .select();

  if (error) {
    console.error("Error creating user:", error.message);
    return null;
  }

  return data;
}

export const getUser = async ({ userId, token }) => {
  const supabase = await supabaseClient(token);
  const { data, error } = await supabase
    .from("users")
    .select("*")
    .eq("user_id", userId);

  if (error) {
    console.error("Error fetching user:", error.message);
    return null;
  }

  return data[0];
}

export const updateThreadMessages = async ({
  threadId,
  messages,
  token,
}) => {
  const supabase = await supabaseClient(token);

  const { data, error } = await supabase
    .from("threads")
    .update({
      messages: messages,
    })
    .eq("thread_id", threadId)
    .select();

  if (error) {
    console.error("Error updating thread messages:", error.message);
    return null;
  }

  return data;
};

export async function semantic_search({ query_embedding, n, userId, threadId }) {
  const supabase = await supabaseClientServiceRole();
  const { data, error } = await supabase.rpc("semantic_search_new", {
    "embedding": query_embedding,
    "match_threshold": 0.75,
    "match_count": n,
    "thread_id": threadId,
    "user_id": userId,
  })

  if (error) {
    console.error("Error semantic_search:", error.message);
    return [];
  }

  return data
}

export async function logSentry({
  userMsgId, inputMsg, sentry1Response,
  botResponse, sentry2Response, threadId,
}) {
  const supabase = await supabaseClientServiceRole();
  const { error } = await supabase
    .from("sentry_logs")
    .insert({
      'user_message_id': userMsgId,
      'user_input': inputMsg,
      'sentry1_response': sentry1Response,
      'bot_response': botResponse,
      'sentry2_response': sentry2Response,
      'thread_id': threadId,
    })

  if (error) {
    console.error("Error logging sentry activation");
    return [];
  }
}
