import { useState } from 'react'
import { Switch } from '@headlessui/react'
import { useDarkMode } from '@/utils/hooks/useDarkMode';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface ToggleProps {
  callbackFn: (state: boolean) => void;
  label1: string;
  label2: string;
}

export default function Toggle({ callbackFn, label1, label2 }: ToggleProps) {
  const { isDarkMode } = useDarkMode()
  const [enabled, setEnabled] = useState(isDarkMode)

  const handleOnChange = (state: boolean) => {
    setEnabled(state)
    callbackFn(state)
  }

  return (
    <Switch.Group>
      <div className='flex justify-between items-center w-full'>
        <Switch.Label className={"text-black dark:text-white flex items-center gap-x-3 rounded-md text-base leading-6 text-[16px]"}>
          {label1}
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={handleOnChange}
          className={classNames(
            enabled ? 'bg-action-primary-default' : 'bg-editable-textbox-dark',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Switch.Label className={"text-black dark:text-white flex items-center gap-x-3 rounded-md text-base leading-6 xs:text-left text-right"}>
          {label2}
        </Switch.Label>
      </div>
    </Switch.Group>
  )
}

