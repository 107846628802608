export const setDIAssistantsPage = ({
  visitorType,
}: {
  visitorType: string;
}) => {
  // @ts-ignore
  const object = di
  object.pageName = "Chat"
  object.siteName = "SMB AI Chatbot"
  object.hierarchy = "SMB AI Chatbot | Chat"
  object.siteSection1 = "SMB AI Chatbot"
  object.siteSection2 = "Chat"
  object.siteSection3 = ""
  object.siteType = "Marketing"
  object.pageURL = window.location.href
  object.pageTitle = "SMB AI Chatbot"
  object.region = "NAM"
  object.country = "US"
  object.language = navigator.language || "en-US"
  object.pageType = "B2C"
  object.visitorType = visitorType
}

export const setDI = ({
  visitorType, pageName, hierarchy, siteSection2,
}: {
  visitorType: string;
  pageName?: string;
  hierarchy?: string;
  siteSection2?: string;
}) => {
  // @ts-ignore
  const object = di
  object.pageName = pageName ?? "Home"
  object.siteName = "SMB AI Chatbot"
  object.hierarchy = hierarchy ?? "SMB AI Chatbot | Home"
  object.siteSection1 = "SMB AI Chatbot"
  object.siteSection2 = siteSection2 ?? "Home"
  object.siteSection3 = ""
  object.siteType = "Marketing"
  object.pageURL = window.location.href
  object.pageTitle = "SMB AI Chatbot"
  object.region = "NAM"
  object.country = "US"
  object.language = navigator.language || "en-US"
  object.pageType = "B2C"
  object.visitorType = visitorType
}

export const setDIHomePage = ({
  visitorType,
}: {
  visitorType: string;
}) => {
  // @ts-ignore
  const object = di
  object.pageName = "Home"
  object.siteName = "SMB AI Chatbot"
  object.hierarchy = "SMB AI Chatbot | Home"
  object.siteSection1 = "SMB AI Chatbot"
  object.siteSection2 = "Home"
  object.siteSection3 = ""
  object.siteType = "Marketing"
  object.pageURL = window.location.href
  object.pageTitle = "SMB AI Chatbot"
  object.region = "NAM"
  object.country = "US"
  object.language = navigator.language || "en-US"
  object.pageType = "B2C"
  object.visitorType = visitorType
}


/* EVENT TRACKING */

/**
* Track when a new chat session is started or an existing one is resumed.
* This function is called when one of the following occurs:
* - User sends a message to a new chat
* - User selects a business stage
* - User selects a different thread (tracked when the user sends a message in an existing thread)
*/
export function trackChatbotStart() {
  // @ts-ignore
  const object = adobeDataLayer
  // @ts-ignore
  object.push({
    event: "chatStart",
    trackAction: {
      botAction: "start",
      pageName: "homepage",
    }
  })
}

/**
* Track when a chat session is closed
* This function is called when one of the following occurs:
* - User gets logged out after a period of inactivity
* - User closes window
* - User selects a different thread
* - User clicks on new chat button
*/
export function trackChatbotClose(startTime: Date) {
  const endTime = new Date()
  // @ts-ignore
  const diffSec = Math.abs((endTime - startTime) / 1000)
  const seconds = Math.floor(diffSec % 60)
  const minutes = Math.floor((diffSec % 3600) / 60)
  const hours = Math.floor(diffSec / 3600)

  // @ts-ignore
  adobeDataLayer.push({
    event: "chatClose",
    trackAction: {
      botAction: "close",
      pageName: "homepage",
      chatLength: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
    }
  })
}

/**
* Track when a link/button is clicked
*/
export function trackLinkClick({ section, clickName }: {
  section: string;
  clickName: string;
}) {
  // @ts-ignore
  adobeDataLayer.push({
    event: "linkClick",
    trackAction: {
      clickSecion: section,
      clickName: clickName,
      pageName: "homepage",
    }
  })
}

/**
* Track user selecting a business stage
*/
export function trackStageSelection(prompt: string) {
  // @ts-ignore
  adobeDataLayer.push({
    event: "chatClick",
    trackAction: {
      clickSection: "let's_get_started",
      promptClick: prompt,
      pageName: "homepage",
    }
  })
}

/**
* Track user clicks on a button in the chat section
*/
export function trackChatClick(clickName: string) {
  // @ts-ignore
  adobeDataLayer.push({
    event: "chatClick",
    trackAction: {
      clickSection: "in_chat",
      promptClick: null,
      clickName,
      pageName: "homepage",
    }
  })
}

/**
* Track flow starts
*/
export function trackFlowStart({ flowName, clickName }: {
  flowName: string;
  clickName: string;
}) {
  // @ts-ignore
  adobeDataLayer.push({
    event: "flowStart",
    trackAction: {
      flowName,
      clickName,
      pageName: "homepage",
    }
  })
}

/**
* Track flow completion
*/
export function trackFlowComplete({ flowName, clickName, optionSelected }: {
  flowName: string;
  clickName: string;
  optionSelected?: string;
}) {
  // @ts-ignore
  adobeDataLayer.push({
    event: "flowComplete",
    trackAction: {
      flowName,
      clickName,
      pageName: "homepage",
      ...(optionSelected ? { optionSelected } : {})
    }
  })
}

