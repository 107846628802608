import { IconProps } from "@/utils/types"

interface DeleteIcon extends IconProps {
}

export function DeleteIcon(props: DeleteIcon) {
  const { className, ...rest } = props

  return (
    <div {...rest} className={`cursor-pointer ${className}`}>
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path className="fill-[#CF4500] dark:fill-white cursor-pointer group-hover:fill-black dark:group-hover:fill-orange-100" d="M19.0909 2.68109H8.72727V2.13563C8.72727 1.23345 7.99309 0.499268 7.09091 0.499268H1.63636C0.734182 0.499268 0 1.23345 0 2.13563V5.95381V6.49927V19.0447H13.7531C13.5753 18.6989 13.4313 18.3345 13.3233 17.9538H1.09091V7.04472H19.6364V10.3447C20.0116 10.3785 20.3749 10.4494 20.7273 10.5498V6.49927V5.95381V4.31745C20.7273 3.41527 19.9931 2.68109 19.0909 2.68109ZM1.09091 5.95381V2.13563C1.09091 1.83563 1.33527 1.59018 1.63636 1.59018H7.09091C7.392 1.59018 7.63636 1.83563 7.63636 2.13563V3.77199H19.0909C19.392 3.77199 19.6364 4.01745 19.6364 4.31745V5.95381H1.09091Z" />
        <path className="fill-[#CF4500] dark:fill-white cursor-pointer group-hover:fill-black dark:group-hover:fill-orange-100" d="M19.0907 11.4084C16.3842 11.4084 14.1816 13.611 14.1816 16.3175C14.1816 19.0241 16.3842 21.2266 19.0907 21.2266C21.7973 21.2266 23.9998 19.0241 23.9998 16.3175C23.9998 13.611 21.7973 11.4084 19.0907 11.4084ZM19.0907 20.1357C16.9853 20.1357 15.2725 18.423 15.2725 16.3175C15.2725 14.2121 16.9853 12.4994 19.0907 12.4994C21.1962 12.4994 22.9089 14.2121 22.9089 16.3175C22.9089 18.423 21.1962 20.1357 19.0907 20.1357Z" />
        <path className="fill-[#CF4500] dark:fill-white cursor-pointer group-hover:fill-black dark:group-hover:fill-orange-100" d="M20.6337 14.0034L19.0912 15.546L17.5486 14.0034L16.7773 14.7747L18.3199 16.3172L16.7773 17.8598L17.5486 18.6311L19.0912 17.0885L20.6337 18.6311L21.405 17.8598L19.8624 16.3172L21.405 14.7747L20.6337 14.0034Z" />
      </svg>
    </div>
  )
}


